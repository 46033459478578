import Cookie from 'js.cookie';
import lightGallery from 'lightgallery';
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'
import fullScreen from 'lightgallery/plugins/fullscreen'

export default {
  init() {
    // JavaScript to be fired on all pages

  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    var removeClass = true;
    $('.hamburger').click(function () {
      $('.hamburger').toggleClass('is-active');
      $('#sideNav').toggleClass('sideNav-open');
      $('.sideNavBody').toggleClass('sideNavBody-push');
      removeClass = false;
    });

    $('.sideNav').click(function () {
      removeClass = false;
    });

    if (!Cookie.get('alert') && $('#modal').length || $('#modal').hasClass('no-cookie')) {
      $('#modal').show();
      Cookie.remove('alert');
    }

    $('.modal .btn-close').click(function () {
      $('#modal').hide();
      Cookie.set('alert', true, {
        expires: 1,
      });
    });

    document.addEventListener('touchstart', function (e) {
      if (removeClass && !$(e.target).hasClass('sideNav') && $('.sideNav').has($(e.target)).length === 0) {
        $('.hamburger').removeClass('is-active');
        $('#sideNav').removeClass('sideNav-open');
        $('.sideNavBody').removeClass('sideNavBody-push');
      }
      removeClass = true;
    }, false);

    //dropdown on click
    $('.banner nav li.menu-item-has-children > a').click(function (e) {
      e.preventDefault();
      $(this).next('.banner .sideNav li.menu-item-has-children .sub-menu').slideToggle();
      $(this).parent('.banner nav li.menu-item-has-children').toggleClass('open');
      $(this).parent('.banner nav li.menu-item-has-children').siblings('.open').removeClass('open');
    });

    // close dropdown when clicking anywhere else
    $(document).on('click touch', function (e) {
      if (!$(e.target).hasClass('.banner nav li.menu-item-has-children') && !$(e.target).parents('.banner nav li.menu-item-has-children').length) {
        $('.banner .sideNav li.menu-item-has-children .sub-menu').slideUp();
        $('.banner nav li.menu-item-has-children.open').removeClass('open');
      }
    });

    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $('.scrollup').fadeIn();
      } else {
        $('.scrollup').fadeOut();
      }
    });

    $('.scrollup').click(function () {
      $('html, body').animate({
        scrollTop: 0,
      }, 600);
      return false;
    });

    if ($('.testimonials-carousel').length) {
      $('.testimonials-carousel').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        appendArrows: '.testimonials-panel .slider-nav',
        appendDots: '.testimonials-panel .slider-nav',
        adaptiveHeight: false,
        arrows: true,
        dots: true,
        responsive: [{
            breakpoint: 1439,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              arrows: true,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: false,
            },
          },
        ],
      });
    }

    // Event delegation to handle events for dynamically created sliders
    $(document).on('mousedown', '.slider', (e) => {
      // Add hide-after class to .before-img when slider is being moved
      $(e.target).siblings('.before-img').addClass('hide-after');
      $(e.target).siblings('.after-img').addClass('hide-after');
    });

    $(document).on('mouseup', '.slider', (e) => {
      // Remove hide-after class from .before-img when slider is not being moved
      $(e.target).siblings('.before-img').removeClass('hide-after');
      $(e.target).siblings('.after-img').removeClass('hide-after');
    });

    // Event delegation to handle input and change events for dynamically created sliders
    $(document).on('input change', '.slider', (e) => {
      const sliderPos = e.target.value;
      const $beforeImg = $(e.target).siblings('.before-img');

      // Update the width of the foreground image within the parent slider-container
      $beforeImg.css('width', `${sliderPos}%`);
      // Update the position of the slider button within the parent slider-container
      $(e.target).siblings('.slider-button').css('left', `calc(${sliderPos}% - 23px)`);
    });

    // Loop through each project-gallery element and initialize lightSlider and lightGallery
    $('.project-gallery').each(function (index) {
      var projectGalleryId = 'project-gallery-' + (index + 1);
      var projectGallery = document.getElementById(projectGalleryId);

      // Initialize lightSlider
      $(projectGallery).lightSlider({
        gallery: true,
        item: 1,
        loop: true,
        thumbItem: 5,
        slideMargin: 0,
        verticalHeight: 250,
        controls: true,
        currentPagerPosition: 'left',
        vThumbWidth: 50,
        freeMove: true,
        nextHtml: '<i class="fa-solid fa-circle-caret-right"></i>',
        prevHtml: '<i class="fa-solid fa-circle-caret-left"></i>',
      });

      // Initialize lightGallery
      lightGallery(projectGallery, {
        licenseKey: 'F28B4CA0-283749F1-BBEB75B9-915E2B0B',
        selector: '.lslide',
        plugins: [lgZoom, lgThumbnail, fullScreen],
        speed: 500,
        preload: 1,
        fullScreen: true,
        mobileSettings: {
          controls: true,
          showCloseIcon: true,
          download: false,
        },
      });
    });

    //review us page
    $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
      localStorage.setItem('activeTab', $(e.target).attr('href'));
    });
    var activeTab = localStorage.getItem('activeTab');
    if (activeTab) {
      $('#review-tab a[href="' + activeTab + '"]').tab('show');
    }

    //add dynamic More link to testimonial text
    var testimonialClass = 'testimonial-quote'; //class that wraps testimonial text in html

    $('.' + testimonialClass).each(function () {
      var maxLength = 190;
      var text = $(this).text();
      if (text.length > maxLength) {
        var shortText = text.substr(0, maxLength);
        var longText = text.substr(maxLength);
        var html = shortText + '<a href="#" class="read-more">... More</a><span class="more-text">' + longText + '</span>';
        $(this).html(html);
        $(this).find('.more-text').hide();
      }
    });

    $('.slick-slide .read-more').click(function (e) {
      e.preventDefault();
      $(this).hide();
      $(this).siblings('.more-text').show();
      $(this).closest('.slick-slide').siblings().find('.more-text').hide();
      $(this).closest('.slick-slide').siblings().find('.read-more').show();
    });

    $('.slick-arrow, .slick-dots button').click(function (e) {
      e.preventDefault();
      $('.' + testimonialClass + ' .more-text').hide();
      $('.' + testimonialClass + ' .read-more').show();
    });

  },

};
